/*eslint-disable*/ 
import React from "react";
import Layout from 'components/layout'
import Seo from 'components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles";
import { Link, graphql } from "gatsby"
import Image from "gatsby-image";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Typography from '@material-ui/core/Typography'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";

// kit
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";

import Primary from "components/Typography/Primary.js";
import Brown from "components/Typography/Brown.js";

import shingenStyle from "style/kit_shingenStyle.js";
import SectionSodan2 from "views/Shingen/SectionSodan2.js";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import Mail from "@material-ui/icons/Mail";

// img
import logo_l from "images/logo_l.svg"
import ti1 from "images/tit1.png"
import ti2 from "images/tit2.png"
import ti3 from "images/tit3.png"
import ti4 from "images/tit4.png"
import ti5 from "images/tit5.png"
import sorry from "images/sorry.png"
import line_icon from "images/line_logo.svg";
import { Block, Timer3Sharp } from "@material-ui/icons";

const useStyles = makeStyles(shingenStyle);
const Styles = makeStyles((theme) => ({
  sec_flex:{
    display: 'flex',
    listStyle: 'none',
    '& > li':{
      display: 'block',
    },
  },
  toiawase_area:{
    [theme.breakpoints.down('lg')]: {
      '& > div':{ marginBottom: '0', }
    },
  },
  sectionTitle_company:{
    fontSize: '.9em',
    minWidth: 'none',
    whiteSpace: 'nowrap',
    '& > div':{
      '&:first-child':{
        minWidth: 'none',
      },
    },
    '& h2':{
      padding: '.75em 1em',
      minWidth: 'none',
    },
  },
  sec_table: {
    display: 'table',
    '& > li':{
      display: 'table-cell',
      verticalAlign: 'top',
    },
  },
  sec_t_title:{
    fontSize: '.9em',
    minWidth: '17em!important',
  },
  sec_t_title_24:{
    fontSize: '.9em',
    minWidth: '22.5em!important',
  },  
  gaiyo:{
    borderCollapse: 'inherit',
    borderSpacing: '.5em',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'table',
      borderTop: '1px dotted #ccc',
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      '& tbody':{
        display: 'block',
        margin: '0 auto',
        '& tr':{
          display: 'block',
          width: '100%',
        },
      },
    },
    '& th':{
      padding: '.25em .75em .75em .75em',
      border: '0',
      borderRight: '1px dotted #ccc',
      borderBottom: '1px dotted #ccc',
      textAlign: 'center',
      fontSize: '.9em',
      minWidth: '8em',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        fontSize: '1em',
        textAlign: 'left',
        border: '0',
        fontWeight: 'bold',
        padding: '.5em 0 0 0',
      },
    },
    '& td':{
      padding: '.25em .75em .75em .75em',
      border: '0',
      borderBottom: '1px dotted #ccc',
      [theme.breakpoints.down('sm')]: {
        '& span':{
          color: 'red',
          whiteSpace: 'nowrap',
        },
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        padding: '0 .75em .75em .75em',
      },
    },
  },
  access_map:{
    width: '100%',
    height: '400px',
    border: 'solid 1px #ccc',
  },
  anchor:{
    display: 'block',
    paddingTop: '8em',
    marginTop: '-8em',
  },
  h2_o:{
    minWidth: '8em!important',
    [theme.breakpoints.down('sm')]: {
      padding: '.5em 1em!important',
      height: '3.4em!important',
    },
  },
  inlineblock:{
    display: 'inline-block!important',

  },
  o_button:{
    margin: '1em auto',
    paddingTop: '1em',
  },
}))
const CompanyIndex = () => {
  const classes = useStyles();
  const cl = Styles();

  const siteTitle = "申源について"
  const pageTitle = "申源について"
  const siteDescription = "申源では、お客様に満足いただけるよう全力でサポートいたします。"
  
  return (
    <Layout active="4">
      <Seo title={siteTitle} description={siteDescription}/>
      <div>
        <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.textCenter} >
                <h1 className={classes.title_2} id="logo_l">
                  <img src={logo_l} alert="有限会社 申源"/>
                </h1>
                <h2 className={classes.h2_subtitle}>{ pageTitle }</h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
              <Primary className={classes.description}>
                {siteDescription}
              </Primary>
              <div className={classes.section}>
                <span className={cl.anchor} id="c1"></span>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={ cl.sec_title }>
                    <div className={classes.sectionTitle}>
                      <Brown className={cl.sec_t_title}>
                        <img src={ti3} alt="" />
                        <h2 className={cl.h2_o}>
                          <span>お問い合わせ</span>
                        </h2>
                      </Brown>
                      <div>
                        <p>
                          どんなことでもお気軽にご相談ください。<br />
                        </p>
                        <div  className={classes.sodan2_box}>
                          <div>
                            <span className={classes.sodan_add}>
                              <span>
                                有限会社 申源
                              </span>
                              <span>
                                愛知県豊橋市駅前大通3丁目83<br />
                                営業時間：月曜～金曜 9:00～17:30<br/>
                                4月～11月は第2土曜 9:00～17:30　<span>12月～3月は第2.4土曜 9:00～17:30</span><br/>
                                （土曜不定休、日曜定休、<span>GW・お盆・年末年始休業あり）</span><br/>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <SectionSodan2 />
              </div>
              <div className={classes.section}>
                <span className={cl.anchor} id="c2"></span>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={ cl.sec_title }>
                    <div className={classes.sectionTitle}>
                      <Brown className={cl.sec_t_title_24}>
                        <img src={ti1} alt="" />
                        <h2 className={cl.h2_o}>
                          <span>ご来店のご案内</span>
                        </h2>
                      </Brown>
                      <div>
                        <p>
                          お車でお越しの際は公共駐車場へお停めください。契約によっては、駐車券をお出しします。<br />
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <iframe className={cl.access_map} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASzqnCrYBWXhFtdlXXBg_KCLxQTa1I5Y4&amp;q=34.7623724%2C137.3876252&amp;center=34.7625754563%2C137.3876252&amp;zoom=17&amp;maptype=roadmap"></iframe>
              </div>
              <div className={classes.section}>
                <span className={cl.anchor} id="c3"></span>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={ cl.sec_title }>
                    <div className={classes.sectionTitle}>
                      <Brown className={cl.sec_t_title}>
                        <img src={ti2} alt="" />
                        <h2 className={cl.h2_o}>
                          <span>会社概要</span>
                        </h2>
                      </Brown>
                      <div>
                        <table className={cl.gaiyo}>
                          <tbody>
                            <tr><th>名称</th><td>有限会社 申源</td></tr>
                            <tr><th>許認可番号</th><td>宅地建物取引業者免許：<span>愛知県知事免許（9）第14657号　東三河支部</span><br/>賃貸住宅管理業者登録：<span>国土交通大臣（01） 第005722号</span></td></tr>
                            <tr><th>住所</th><td>愛知県豊橋市駅前大通3丁目83</td></tr>
                            <tr><th>TEL</th><td>0532-56-2259</td></tr>
                            <tr><th>FAX</th><td>0532-56-1456</td></tr>
                            <tr>
                              <th>営業時間</th>
                              <td>
                                月曜～金曜 9:00～17:30<br/>
                                4月～11月は第2土曜 9:00～17:30　<span>12月～3月は第2.4土曜 9:00～17:30</span><br/>
                                  （土曜不定休、日曜定休、<span>GW・お盆・年末年始休業あり）</span><br/>
                              </td>
                            </tr>
                            <tr><th>取締役</th><td>横山章江</td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.section}>
                <span className={cl.anchor} id="c4"></span>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={ cl.sec_title }>
                    <div className={classes.sectionTitle}>
                      <Brown className={cl.sec_t_title}>
                        <img src={ti5} alt="" />
                        <h2 className={cl.h2_o}>
                          <span>明渡通知書</span>
                        </h2>
                      </Brown>
                      <div>
                        <p>
                          退去予告は１ヶ月前以上です。契約書をご確認ください。<br/>
                          こちらを使用して郵送、FAXする場合、必ず当社まで連絡下さい。<br/>
                          立会い日時は予約制です。<br/>
                          原則、当社営業時間の9:00～15:00に現地集合にて行います。<br/>
                          時間は20～30分程です。
                        </p>
                        <p className={cl.o_button}>
                          <Button color="primary" href="https://akiya.shingen.info/shingen_akewatasituchisho.pdf">明渡通知書をダウンロード</Button>
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.section}>
                <span className={cl.anchor} id="c5"></span>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={ cl.sec_title }>
                    <div className={classes.sectionTitle}>
                      <Brown className={cl.sec_t_title}>
                        <img src={ti5} alt="" />
                        <h2 className={cl.h2_o}>
                          <span>車庫証明</span>
                        </h2>
                      </Brown>
                      <div>
                        <p>
                          車庫証明の発行をご希望の方は事務所に書類をご持参ください（要手数料）。<br/>
                          郵送対応等については電話でお問い合わせください。
                        </p>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyIndex

